import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {numberWithSpaces} from "../utils"

class PageAnnonces extends Component {
  render() {
    const { titre, sousTitre, sousBodyRich, headingImage, annonces, cta, aucuneAnnonce } = this.props.data.contentfulPageAnnonces
    const path = this.props.location ? this.props.location.pathname : null

    return (
      <Layout path={path}>
        <PageHeader title={titre} sousTitre={sousTitre} sousBody={sousBodyRich}
                    headingImage={headingImage} cta={cta}/>
        {annonces ? (
          <div id="page-annonces">
            {
              annonces.map((annonce, i) => (
                <div id="annonces-card">
                  <div id="annonces-card--img">
                    <Link to={annonce.slug}>
                      <GatsbyImage image={getImage(annonce.apercu)} alt={annonce.apercu.description} className="img"/>
                    </Link>
                  </div>
                  <div id="annonces-card--info">
                    <h5>{annonce.titre}</h5>
                    <span id="annonces-card--info--subtitle">{annonce.subtitle}</span>
                    <span id="annonces-card--info--subtitle">{annonce.ville}{annonce.codePostal && " - " + annonce.codePostal}</span>
                    <span id="annonces-card--info--price">{numberWithSpaces(annonce.prix)} €</span>
                    <p>{annonce.excerpt}</p>
                    <Link to={annonce.slug} id="annonces-card--info--cta"><span>Voir l'annonce</span></Link>
                  </div>
                </div>
              ))
            }
          </div>
        ) : (
          <div className="error-container">
            <h5>{aucuneAnnonce}</h5>
          </div>
        )}
      </Layout>
    )
  }
}

PageAnnonces.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageAnnonces

export const pageAnnoncesQuery = graphql`
  query pageAnnoncesQuery($slug: String!) {
    contentfulPageAnnonces(slug: { eq: $slug }) {
      slug
      titre
      aucuneAnnonce
      sousTitre
      sousBodyRich {
        raw
      }
      cta
      headingImage {
        description
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          width: 660
        )
      }
      annonces {
        titre
        prix
        slug
        excerpt
        subtitle
        ville
        codePostal
        apercu {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 85
            width: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
